
<template>
  <div>
    <b-card no-body class="m-0">
      <div class="m-1">
        <b-row class="d-flex justify-content-between w-100">
          <!-- <div class="w-100 d-flex justify-content-end mb-2"> -->
          <b-col md="6" class="d-flex mb-3">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              v-model="per_page"
              style="height: 20px"
              class="per-page-selector d-inline-block mx-50 w-25"
              :options="['5', '10', '20', '50', '100']"
              @input="changePagination()"
            />
            <!-- Search -->
          </b-col>
          <b-col md="6" class="d-flex mt-1">
            <div class="w-100 d-flex justify-content-end">
            </div>
          </b-col>
          <!-- </div> -->
        </b-row>
        <section id="card-images" class="container1">
          <b-row
            md="12"
            class="d-flex justify-content-center align-items-center"
          >
            <b-table
              :busy="isLoading"
              ref="refUserListTable"
              class="position-relative"
              :items="data"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="No matching records found"
              :sort-desc.sync="isSortDirDesc"
              @sort-changed="onSortChanged"
            >
              <template #table-busy>
                <div class="text-center text-secondary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
              <template #cell(id)="data">
                <span
                  class="text-primary font-weight-bold"
                  style="font-size: 15px"
                >
                  {{ data.index + 1 }}
                </span>
              </template>
              <!-- <template #thead-top="{ fields }">
                  <tr>
                    <th v-for="(field, index) in fields" :key="index">
                      <template v-if="field.key == 'Action'"> &nbsp; </template>
                      <template v-else>
                        <b-form-input
                          v-model="columnFilters[field.key]"
                          @input="filterTable()"
                          placeholder="search..."
                        />
                      </template>
                    </th>
                  </tr>
                </template> -->

              <template #cell(name)="data">
                <div
                  class="d-flex align-items-center text-primary font-weight-bolder"
                >
                  <span class="mr-1">
                    <template v-if="data.item && data.item.image !== null && data.item.image !== ''">
                      <b-img
                        height="60px"
                        width="60px"
                        class="mr-1 titleName"
                        :src="data && data.item ? data.item.image : ''"
                      ></b-img>
                    </template>
                    <template v-else>
                      <span>
                        <img
                          style="height: 40px; width: 40px"
                          src="../../../../../public//no-image.png"
                          alt=""
                        />
                      </span>
                    </template>
                  </span>

                  <span class="mr-1">
                    <span class="mr-1">
                      {{ data.item.name }}
                    </span>
                  </span>
                </div>
              </template>

              <template #cell(Quantity)="data">
                <span class="mr-1 titleName">
                  {{ data.item.quantity }}
                </span>
              </template>
              <template #cell(QTY_Measurment)="data">
                <span class="mr-1 titleName">
                  <!-- {{ data.item.unit_id }} -->
                  {{ data.item && data.item.unit ? data.item.unit.name : "" }}
                </span>
              </template>
              <template #cell(Calories)="data">
                <span class="mr-1 titleName">
                  {{ data.item.calories }}
                </span>
              </template>
              <template #cell(Carbs)="data">
                <span class="mr-1 titleName">
                  {{ data.item.carbs }}
                </span>
              </template>
              <template #cell(Protein)="data">
                <span class="mr-1 titleName">
                  {{ data.item.protein }}
                </span>
              </template>
              <template #cell(Fat)="data">
                <span class="mr-1 titleName">
                  {{ data.item.fats }}
                </span>
              </template>
              <template #cell(Action)="data">
                <div class="d-flex align-items-center">
                 
                  <feather-icon
                    class="mr-2"
                    icon="XIcon"
                    size="25"
                    style="cursor: pointer"
                    @click="handleReject(data.item.id)"
                    />
                    <feather-icon
                    icon="CheckIcon"
                    size="25"
                    style="cursor: pointer"
                    @click="handleApproval('approve', data.item.id)"
                  />
                  <feather-icon
                      icon="EyeIcon"
                      size="22"
                      class="m-1"
                      @click="openData(data.item)"
                      style="cursor: pointer"
                    />
                    <feather-icon
                    icon="EditIcon"
                    size="22"
                    class="cursor-pointer mr-1"
                    @click="handleEditForm(data.item)"
                  />
                </div>
              </template>
            </b-table>
            <div class="w-100 d-flex justify-content-between p-1">
              <!-- Pagination -->
              <div>
                <span class="text-nowrap">
                  Showing {{ paginationStart }} to {{ paginationEnd }} of
                  {{ paginationTotal }}</span
                >
              </div>
              <b-pagination
                :total-rows="paginationTotal"
                :per-page="per_page"
                v-model="paginationValue"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="changePagination()"
              >
                <!-- <div>
                  <button
                    @click="goToPage(paginationValue - 1)"
                    :disabled="paginationValue === 1"
                  >
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </button>
                  <button
                    @click="goToPage(paginationValue + 1)"
                    :disabled="paginationValue * per_page >= paginationTotal"
                  >
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </button>
                </div> -->
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
              
              <b-modal
                class="d-flex justify-content-between"
                no-close-on-backdrop
                size="md"
                v-model="rejectModal"
                title="Reason"
                hide-footer
              >
                <validation-observer ref="reason">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="reason"
                        >
                          <b-form-textarea
                            id="reason"
                            v-model="reason"
                            placeholder="Enter Reason"
                          ></b-form-textarea>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                      <b-col md="12" class="d-flex justify-content-end mt-2">
                        <b-button
                          variant="primary"
                          class="mr-1 font-weight-bold"
                          @click.prevent="handleReasonSubmit('reject',selectedId)"
                          style="
                            font-size: 15px;
                            font-family: 'Public Sans', sans-serif;
                          "
                          :disabled="flag"
                        >
                          Submit
                        </b-button>
                        <b-button
                          type="reset"
                          class="font-weight-bold"
                          style="
                            font-size: 15px;
                            font-family: 'Public Sans', sans-serif;
                          "
                          variant="primary"
                          @click="handleCancel"
                        >
                          Cancel
                        </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-modal>

              <b-modal
              hide-footer
              top
              no-close-on-backdrop
              size="md"
              title="Edit Nutrition"
              v-model="addNutrition">
                <Form :selectedItem="selectedItem" :ifEdit="ifEdit" :handleCloseForm='handleCloseForm' :getNutriData="getNutrition"/>
              </b-modal>
              <b-modal
              hide-footer
              top
              no-close-on-backdrop
              size="md"
              v-model="viewNutritionModal"
            >
            <view-nutrition :selectedItem="selectedItem"></view-nutrition>
            </b-modal>
          </b-row>
        </section>
      </div>
    </b-card>
  </div>
</template>
  <script>
import BootstrapVue from "bootstrap-vue";
import {
  BRow,
  BCol,
  BCardText,
  BCardTitle,
  BPagination,
  BButton,
  BCard,
  BForm,
  BTable,
  BFormFile,
  BFormInput,
  BFormGroup,
  BBadge,
  BSpinner,
  BFormTextarea,
  BImg,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "../../../components/axios";
import { required } from "@validations";
import _ from "lodash";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import Form from './Form.vue'
import viewNutrition from './View'

export default {
  components: {
    BRow,
    BTabs,
    BTab,
    vSelect,
    BCol,
    BCardText,
    BImg,
    BCardTitle,
    BButton,
    BPagination,
    BCard,
    BForm,
    BTable,
    BFormFile,
    BFormInput,
    BFormGroup,
    BBadge,
    ValidationProvider,
    BSpinner,
    ValidationObserver,
    quillEditor,
    BFormTextarea,
    Form,
    viewNutrition
  },
  data() {
    return {
      snowOption: {
        theme: "snow",
      },
      flag: false,
      tableColumns: [
        { key: "id", lable: "No", sortable: true },
        { key: "name", label: "Nutrition Name", sortable: true },
        { key: "quantity", label: "Quantity", sortable: true },
        {
          key: "unit.name",
          label: "QTY Measurment",
          type: "dropdown",
          displayName: "name",
        },
        { key: "calories", label: "Calories", sortable: true },
        { key: "carbs", label: "Carbs", sortable: true },
        { key: "protein", label: "Protein", sortable: true },
        { key: "fats", label: "Fats", sortable: true },
        { key: "Action", label: "Actions" },
      ],
      isSortDirDesc: true,
      required: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      selectedId: null,
      sortBy: "",
      orderBy: true ? "ASC" : "DESC",
      clickedRowData: null,
      isSideDataLoading: false,
      openModel: false,
      staticUsers: [],
      id: 0,
      isLoading: false,
      selectedItem: {},
      ifEdit: false,
      paginationValue: 1,
      paginationTotal: 0,
      per_page: 10,
      paginationStart: 0,
      paginationEnd: 0,
      image: "",
      columnFilters: {},
      params: {},
      activeTab: "allCustomers",
      data: [],
      rejectModal:false,
      selectedId:0,
      addNutrition:false,
      ifEdit:false,
      selectedItem:{},
      viewNutritionModal:false
    };
  },
  mounted() {
    this.getNutrition();
  },
  methods: {
    handleCloseForm(){
      this.ifEdit=false;
      this.selectedItem={};
      this.addNutrition=false
    },
    async handleEditForm(item) {
      this.addNutrition = true;
      this.ifEdit = true;
      this.selectedItem=item
    },
    openData(item){
      this.selectedItem=item,
      this.viewNutritionModal =true
    },
    handleCancel(){
      this.selectedId=0;
      this.reason='';
      this.rejectModal=false
    },
    onSortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderBy = e.sortDesc ? "DESC" : "ASC";
      // console.log(this.sortBy)
      // console.log(this.orderBy)
      this.getNutrition();
    },
    handleReject(id){
      this.selectedId = id
      this.reason=''
      this.rejectModal =true
    },
    handleReasonSubmit(e,id){
      this.$refs.reason.validate().then((success)=>{
        if(success){
            this.handleApproval(e,id)      
        }
      })
    },
    handleApproval(e, id) {
      // const customerData = {
      //   approve: e,
      //   id: id,
      // };
      let confirmation;
      if (e === "approve") {
        confirmation = "Yes, Approve it!";
      } else {
        confirmation = "Yes, Reject it!";
      }
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: confirmation,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.flag=true
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            url: `${this.baseApi}nutrition/approve-reject`,
            data:{
              reason:this.reason,
              id:id,
              type:e
            }
            // data: JSON.stringify(customerData),
          };
          axios(requestOptions)
            .then((response) => {
              this.$swal({
                title: "Submitted",
                text: response.data.message
                  ? `${response.data.message}`
                  : response.data.success
                  ? `${response.data.success}`
                  : "Video Updated Successfully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.getNutrition();
              this.rejectModal=false
              this.$forceUpdate();

            })
            .catch((error) => {
              if (error.data.message) {
                this.$swal({
                  title: "Error!",
                  text: error.data.message,
                  icon: "error",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              } else {
                this.$swal({
                  title: "Error!",
                  text: `${error}`,
                  icon: "error",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
            })
            .finally(()=>{
                this.flag=false
            })
        }
      });
    },
    removeHTMLTags(content) {
      return content.replace(/<[^>]+>/g, "");
    },
    filterTable: _.debounce(function () {
      this.params = {};
      for (let key in this.columnFilters) {
        if (
          this.columnFilters[key] &&
          typeof this.columnFilters[key] == "object"
        ) {
          this.params[key] = this.columnFilters[key]
            .map((inner) => inner.id)
            .join(",");
        } else {
          this.params[key] = this.columnFilters[key];
        }
      }
      this.params.page = this.paginationValue;
      this.getNutrition();
    }, 1000),

    changePagination() {
      this.getNutrition();
    },

    goToPage(page) {
      if (
        page >= 1 &&
        page <= Math.ceil(this.paginationTotal / this.per_page)
      ) {
        this.paginationValue = page;
        this.getNutrition();
      }
    },
    async getNutrition() {
      this.isLoading = true;
      let params = {};
      params.page = this.paginationValue;
      params.per_page = this.per_page;
      if (this.search) {
        params.search = this.search.id ? this.search.id : this.search;
        params.type = this.searchTerm.value;
      }
      if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      await axios({
        method: "GET",
        url: `${this.baseApi}nutrition/get-pending`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: params,
      })
        .then((json) => {
          this.data = json?.data.nutrition;
          this.paginationTotal = json?.data.pagination.total;
          this.paginationStart = json?.data.pagination.current_page;
          this.paginationEnd = json?.data.pagination.per_page;
          this.isLoading = false;
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.isLoading = false;
        });
    },

    // async removeRow(id) {
    //   this.$swal({
    //     title: "Are you sure?",
    //     text: "You won't be able to revert this!",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonText: "Yes, delete it!",
    //     customClass: {
    //       confirmButton: "btn btn-primary",
    //       cancelButton: "btn btn-outline-danger ml-1",
    //     },
    //     buttonsStyling: false,
    //   })
    //     .then(async (result) => {
    //       if (result.value) {
    //         await axios({
    //           method: "Delete",
    //           url: `${this.baseApi}cmspage/delete/${id}`,
    //           headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${this.accessToken}`,
    //           },
    //         })
    //           .then(() => {
    //               this.$swal({
    //                 title: "Deleted",
    //                 text: "Deleted successfully",
    //                 icon: "success",
    //                 customClass: {
    //                   confirmButton: "btn btn-primary",
    //                 },
    //                 buttonsStyling: false,
    //               });
    //               this.getNutrition();
    //           })
    //           .catch((error) => {
    //             console.error("Error deleting user:", error);
    //           });
    //       }
    //     })
    //     .catch((error) => console.log(error, "error"))
    //     .finally(() => {});
    // },

    handleCms(id) {
      this.selectedId = id;
      this.$router.push({ path: "/cms/addcms" });
    },
    Close() {
      this.$router.push({ path: "/cms" });
      this.openModel = false;
    },
  },
};
</script>
  <style lang="scss">
.text-danger {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
  
   
