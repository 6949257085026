
<template>
  <div>
        <div class="p-0">
        <b-row>
            <b-col md="12" class="d-flex justify-content-start">
            <h3>Nutrition Details</h3>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" class="mb-1">
            <img :src='selectedItem.image' alt="" class="nutri-img" v-if="selectedItem.image">
                <img v-else style="height:70px;width:70px" src="../../../../../public/no-image.png" alt="">
            </b-col>
            <b-col md="6">
            <div class="d-flex flex-column m-1 w-100">
                <strong>Nutrition Name:</strong> {{ selectedItem.name }}
            </div>
            </b-col>
            <b-col md="6">
            <div class="d-flex flex-column m-1 w-100">
                <strong>Quantity:</strong> {{ selectedItem.quantity }}
            </div>
            </b-col>
            <b-col md="6">
            <div class="d-flex flex-column m-1 w-100">
                <strong>Quantity Measurement:</strong>
                {{ selectedItem.unit ? selectedItem.unit.name : "" }}
            </div>
            </b-col>
            <b-col md="6">
            <div class="d-flex flex-column m-1 w-100">
                <strong>Calories:</strong> {{ selectedItem.calories }}
            </div>
            </b-col>
            <b-col md="6">
            <div class="d-flex flex-column m-1 w-100">
                <strong>Carbs:</strong>{{ selectedItem.carbs }}
            </div>
            </b-col>
            <b-col md="6">
            <div class="d-flex flex-column m-1 w-100">
                <strong>Protein:</strong>{{ selectedItem.protein }}
            </div>
            </b-col>
            <b-col md="6">
            <div class="d-flex flex-column m-1 w-100">
                <strong>Fat:</strong>{{ selectedItem.fats }}
            </div>
            </b-col>
        </b-row>
        <!-- <b-row class="mt-3">
            <b-col>
            <div class="d-flex justify-content-end">
                <b-button variant="primary" @click="handleCloseView">
                Close
                </b-button>
            </div>
            </b-col>
        </b-row> -->
        </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BButton
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton
  },
  props:['selectedItem','handleCloseView'],
  data() {
    return {
     
    };
  },
};
</script>
<style scoped>
.nutri-img{
  max-height: 300px;
  max-width: 300px
}
</style>