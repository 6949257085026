var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"m-0",attrs:{"no-body":""}},[_c('div',{attrs:{"id":"nutritionForm"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{ref:"myForm"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"input"}},[_vm._v("Nutrition Name")]),_c('validation-provider',{attrs:{"rules":"required","name":"Nutrition"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input","maxlength":"80","placeholder":"Name"},model:{value:(_vm.nutrition),callback:function ($$v) {_vm.nutrition=$$v},expression:"nutrition"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"Quantity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticStyle:{"font-family":"Public Sans, sans sarif"},attrs:{"for":"input"}},[_vm._v("Quantity")]),_c('b-form-input',{attrs:{"id":"input","maxlength":"10","label":"name","placeholder":"Quantity"},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"Quantity Measurement"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticStyle:{"font-family":"Public Sans, sans sarif"},attrs:{"for":"input"}},[_vm._v("Quantity Measurement")]),_c('v-select',{attrs:{"id":"input","maxlength":"10","options":_vm.getOptions('unit'),"searchable":false,"clearable":false,"label":"name","placeholder":"Quantity Measurement"},model:{value:(_vm.quantityMeasuerment),callback:function ($$v) {_vm.quantityMeasuerment=$$v},expression:"quantityMeasuerment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"Calories"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticStyle:{"font-family":"Public Sans, sans sarif"},attrs:{"for":"input"}},[_vm._v("Calories")]),_c('b-form-input',{attrs:{"id":"input","maxlength":"10","placeholder":"Calories"},model:{value:(_vm.calories),callback:function ($$v) {_vm.calories=$$v},expression:"calories"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"Carbs"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticStyle:{"font-family":"Public Sans, sans sarif"},attrs:{"for":"input"}},[_vm._v("Carbs")]),_c('b-form-input',{attrs:{"id":"input","maxlength":"10","placeholder":"Carbs"},model:{value:(_vm.carbs),callback:function ($$v) {_vm.carbs=$$v},expression:"carbs"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"Protein"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticStyle:{"font-family":"Public Sans, sans sarif"},attrs:{"for":"input"}},[_vm._v("Protein")]),_c('b-form-input',{attrs:{"id":"input","maxlength":"10","placeholder":"Protein"},model:{value:(_vm.protein),callback:function ($$v) {_vm.protein=$$v},expression:"protein"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required","name":"Fats"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticStyle:{"font-family":"Public Sans, sans sarif"},attrs:{"for":"input"}},[_vm._v("Fats")]),_c('b-form-input',{attrs:{"id":"input","maxlength":"10","placeholder":"Fats"},model:{value:(_vm.fats),callback:function ($$v) {_vm.fats=$$v},expression:"fats"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"input"}},[_vm._v("Nutrition Image")]),_c('validation-provider',{attrs:{"name":"Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":"Image Drop here"},on:{"input":function($event){return _vm.uploadDoc($event, 'image', 'Nutrition')}},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"d-flex justify-content-start mt-2",attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1",staticStyle:{"font-family":"Public Sans, sans sarif"},attrs:{"variant":"primary","disabled":_vm.flag},on:{"click":function($event){return _vm.handleAddRow()}}},[_vm._v(" "+_vm._s(_vm.ifEdit == true ? "Update" : "Save")+" ")]),_c('b-button',{staticStyle:{"font-family":"Public Sans, sans sarif"},attrs:{"type":"reset","variant":"primary"},on:{"click":_vm.handleCloseForm}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }