
<template>
    <div>
      <b-card no-body class="m-0">
          <div  id="nutritionForm">
            <validation-observer ref="simpleRules">
              <b-form ref="myForm">
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label for="input">Nutrition Name</label>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Nutrition"
                      >
                        <b-form-input
                          id="input"
                          maxlength="80"
                          v-model="nutrition"
                          placeholder="Name"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Quantity"
                      >
                        <label
                          for="input"
                          style="font-family: Public Sans, sans sarif"
                          >Quantity</label
                        >
                        <b-form-input
                          id="input"
                          maxlength="10"
                          label="name"
                          v-model="quantity"
                          placeholder="Quantity"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Quantity Measurement"
                      >
                        <label
                          for="input"
                          style="font-family: Public Sans, sans sarif"
                          >Quantity Measurement</label
                        >
                        <v-select
                          id="input"
                          maxlength="10"
                          :options="getOptions('unit')"
                          :searchable="false"
                          :clearable="false"
                          label="name"
                          v-model="quantityMeasuerment"
                          placeholder="Quantity Measurement"
                        ></v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Calories"
                      >
                        <label
                          for="input"
                          style="font-family: Public Sans, sans sarif"
                          >Calories</label
                        >
                        <b-form-input
                          id="input"
                          maxlength="10"
                          v-model="calories"
                          placeholder="Calories"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Carbs"
                      >
                        <label
                          for="input"
                          style="font-family: Public Sans, sans sarif"
                          >Carbs</label
                        >
                        <b-form-input
                          id="input"
                          v-model="carbs"
                          maxlength="10"
                          placeholder="Carbs"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Protein"
                      >
                        <label
                          for="input"
                          style="font-family: Public Sans, sans sarif"
                          >Protein</label
                        >
                        <b-form-input
                          id="input"
                          v-model="protein"
                          maxlength="10"
                          placeholder="Protein"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Fats"
                      >
                        <label
                          for="input"
                          style="font-family: Public Sans, sans sarif"
                          >Fats</label
                        >
                        <b-form-input
                          id="input"
                          v-model="fats"
                          maxlength="10"
                          placeholder="Fats"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label for="input">Nutrition Image</label>
                      <validation-provider #default="{ errors }" name="Image">
                        <b-form-file
                          v-model="image"
                          @input="uploadDoc($event, 'image', 'Nutrition')"
                          placeholder="Image Drop here"
                        ></b-form-file>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="d-flex justify-content-start mt-2">
                    <b-button
                      variant="primary"
                      style="font-family: Public Sans, sans sarif"
                      class="mr-1"
                      @click="handleAddRow()"
                      :disabled="flag"
                    >
                      {{ ifEdit == true ? "Update" : "Save" }}
                    </b-button>
                    <b-button
                      type="reset"
                      variant="primary"
                      style="font-family: Public Sans, sans sarif"
                      @click="handleCloseForm"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </div>
      </b-card>
    </div>
  </template>
  <script>
  import {
    BRow,
    BCol,
    BButton,
    BCard,
    BForm,
    BFormFile,
    BFormInput,
    BFormGroup,
    BBadge,
    BSpinner,
  } from "bootstrap-vue";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import vSelect from "vue-select";
  import axios from "@/component/axios";
  import _ from "lodash";
  import ViewNutrition from './View.vue';
import { required } from '@validations'
  
  export default {
    components: {
        BRow,
        BCol,
        BButton,
        BCard,
        BForm,
        BFormFile,
        BFormInput,
        BFormGroup,
        BBadge,
        BSpinner,
        vSelect,
        ValidationProvider,
        ValidationObserver,
        ViewNutrition
    },
    props:['selectedItem','handleCloseForm','ifEdit','getNutriData'],
    data() {
        return {
        required,
        accessToken: localStorage.getItem("accessToken"),
        baseApi: process.env.VUE_APP_APIENDPOINT,
        nutrition: "",
        calories: "",
        carbs: "",
        protein: "",
        fats: "",
        image: "",
        quantity:'',
        quantityMeasuerment:'',
        flag:false
      };
    },
    watch:{
        'selectedItem':{
            immediate:true,
            handler:'handleEditForm',
            deep:true
        }
    },
    mounted(){
        if(this.ifEdit){
            this.handleEditForm()
        }
    },
    methods: {
      handleAddRow() {
        this.$refs.simpleRules.validate().then((success) => {
          if (success == false) {
            this.$swal({
              title: "Warning",
              timer: 5000,
              text: "Please fill in all fields",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          if (success) {
            this.handleForm();
          }
        });
      },
      async uploadDoc(event, variable, name) {
        this.flag = true;
        if (event) {
          const formData = new FormData();
          formData.append("url", event);
          formData.append("type", "image");
          await axios({
            method: "POST",
            url: `${this.baseApi}attachments/uploadattachment`,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.accessToken}`,
            },
            data: formData,
          })
            .then(async (response) => {
              this[variable] = response.data.data.url;
            })
            .catch((error) => {
              console.log(error, "");
            })
            .finally(() => {
              this.flag = false;
            });
        }
      },
      async handleEditForm() {
        this.nutrition = this.selectedItem.name;
        this.quantity = this.selectedItem.quantity;
        this.quantityMeasuerment = this.selectedItem.unit ? this.selectedItem.unit : "";
        this.calories = this.selectedItem.calories;
        this.carbs = this.selectedItem.carbs;
        this.protein = this.selectedItem.protein;
        this.fats = this.selectedItem.fats;
        this.image = this.selectedItem.image;
        this.$forceUpdate()
        setTimeout(() => {
          const formElement = this.$refs.myForm;
          if (formElement) {
            formElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        }, 100);
        // this.scrollFormIntoView();
        // })
        //     .catch(error => {
        //         console.error('Error update user:', error);
        //     });
        // },
        // scrollFormIntoView(){
        //     const formElement = this.$refs.myForm.$el;
        //     formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      },
      handleForm() {
        this.flag=true
        const customerData = {
          name: this.nutrition,
          unit_id: this.quantityMeasuerment.id,
          quantity: this.quantity,
          calories: this.calories,
          carbs: this.carbs,
          protein: this.protein,
          fats: this.fats,
          image: this.image,
        };
        const requestOptions = {
          method: `${this.ifEdit ? "put" : "post"}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: this.ifEdit
            ? `${this.baseApi}nutrition/update/${this.selectedItem.id}`
            : `${this.baseApi}nutrition/add`,
          data: JSON.stringify(customerData),
        };
        axios(requestOptions)
          .then(() => {
            this.$swal({
              title: "Submitted",
              text: this.ifEdit
                ? "Nutrition Updated Successfully"
                : " Nutrition Added Successfully",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.$forceUpdate();
            this.getNutriData();
            this.handleCloseForm()
          })
          .catch((error) => {
            this.$swal({
              title: "Error!",
              text: `${error}`,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          })
          .finally(()=>{
            this.flag =false
          })
      },
    },
  };
  </script>
  <style lang="scss">
  .text-danger {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  
   
  <style scoped>
  .titleName {
    font-family: "Public Sans", sans-serif;
  }
  .nutri-img{
    max-height: 300px;
    max-width: 300px
  }
  </style>